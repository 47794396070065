import { Work } from "@biblioteksentralen/cordata";
import { Button, IconButton, useDisclosure } from "@biblioteksentralen/react";
import { ComponentProps, useRef } from "react";
import { Heart } from "react-feather";
import {
  FavoriteListWithFavoriteItems,
  useAllFavoriteLists,
} from "../../../components/minside/dataFetchers/useFavorites";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { FavoritePopover } from "./FavoritePopover";

type ReservationButtonProps = {
  work: Work;
} & ComponentProps<typeof Button>;

export const isItemInFavoriteList = (workId: string, items: FavoriteListWithFavoriteItems["items"]) =>
  items.map(({ rediaItem }) => rediaItem.workId).includes(workId);

const isItemInAFavoriteLists = (workId: string, lists: FavoriteListWithFavoriteItems[]) =>
  lists?.some(({ items }) => isItemInFavoriteList(workId, items));

export const FavoriteButton = ({ work, ...buttonProps }: ReservationButtonProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { data } = useAllFavoriteLists();
  const isFavorite = data?.lists && isItemInAFavoriteLists(work.id, data.lists);
  const { t } = useTranslation();

  // Chakra skal egentlig sette fokus tilbake på knappen som åpnet modalen av seg selv
  // Dette funka vist ikke helt når bruker ikke var logget inn og man får innloggingskjemaet i en modal, men om vi sender med en finalFocusRef så funker det.
  // Vi trenger ikke å kalle fokus på finalFocusRef selv, det gjør chakra for oss.
  const onCloseFocusRef = useRef<HTMLDivElement>(null);

  return (
    <FavoritePopover
      popoverTrigger={
        <IconButton
          icon={<Heart fill={isFavorite ? "black" : "none"} />}
          aria-label={t("Legg til i liste")}
          ref={onCloseFocusRef}
          backgroundColor="whiteAlpha.700"
          boxShadow="lg"
          onClick={onToggle}
          variant="plain"
          borderRadius="50%"
          padding=".2rem"
          {...buttonProps}
        />
      }
      work={work}
      isOpen={isOpen}
      onClose={onClose}
      onCloseFocusRef={onCloseFocusRef}
    />
  );
};
