import { Work } from "@biblioteksentralen/cordata";
import { isAtLeastOne } from "@biblioteksentralen/types";
import { RefObject } from "react";
import ErrorBoundary from "../../../components/errorPages/ErrorBoundary";
import { ModalContainer } from "../../../components/ModalContainer";
import PopoverContainer from "../../../components/PopoverContainer";
import { LoginForm } from "../../../rediaPlatform/LoginForm";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { getRelevantManifestations } from "../../cordata/manifestations";
import { SortableManifestation } from "../../types";
import { useCurrentVariantCharacteristics } from "../works/WorkViewBanner/useCurrentVariantCharacteristics";
import FavoritePopoverBody from "./FavoritePopoverBody";

type PatronListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  work: Work;
  onCloseFocusRef: RefObject<HTMLDivElement>;
  popoverTrigger: React.ReactNode;
};

export const FavoritePopover = ({ isOpen, onClose, work, onCloseFocusRef, popoverTrigger }: PatronListModalProps) => {
  const { t } = useTranslation();
  const { user, isSessionReady } = useRediaPlatformContext();

  const headingTitle = t("Legg i mine lister");

  const handleClose = () => {
    onClose();
  };

  if (isSessionReady && !user) {
    return (
      <>
        {popoverTrigger}
        <ModalContainer heading={headingTitle} isOpen={isOpen} onClose={handleClose} finalFocusRef={onCloseFocusRef}>
          <LoginForm />
        </ModalContainer>
      </>
    );
  }

  return (
    <PopoverContainer
      popoverTrigger={popoverTrigger}
      heading={headingTitle}
      isOpen={isOpen}
      onClose={handleClose}
      isLazy // Bruker isLazy for lazy loading for å unngå at FavoritePopoverBody lastes inn før den er synlig.
    >
      <FavoritePopoverWrapper work={work} onClose={onClose} />
    </PopoverContainer>
  );
};

// Lazy loading wrapper
const FavoritePopoverWrapper = ({ work, onClose }: { work: Work; onClose: () => void }) => {
  const { currentLanguagesList, currentDocumentType } = useCurrentVariantCharacteristics(work);
  const manifestations = getRelevantManifestations(work, currentLanguagesList, currentDocumentType);

  return (
    <ErrorBoundary boundaryName="FavoritePopoverWrapper">
      {isAtLeastOne<SortableManifestation>(manifestations) && (
        <FavoritePopoverBody manifestations={manifestations} work={work} onClose={onClose} />
      )}
    </ErrorBoundary>
  );
};
