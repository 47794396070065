import { Work } from "@biblioteksentralen/cordata";
import { getWorkData } from "@libry-content/integrations";
import { getDocumentTypes } from "../../../searchAndCollection/cordata/manifestations";
import { MinsidePublication } from "./minsidePublicationMap";

export type MinsideWork = {
  id: string;
  publicationId: string;
  coverUrl: string | undefined;
  title: string;
  creators: string[] | undefined;
  mediaTypes: string[];
  workUrl: string | undefined;
  workId: string | undefined;
};

type MinsideWorkMap = {
  id: string;
  publicationId: string;
  work: Work;
  representativeManifestationId: string;
};

export function minsideWorkMap({
  id,
  publicationId,
  work,
  representativeManifestationId,
}: MinsideWorkMap): MinsidePublication {
  const workResult = {
    work,
    representativeManifestationId,
  };

  const { imageUrl, formattedTitle, mainContributor } = getWorkData(workResult);
  const author = mainContributor?.agent.name.nb ?? mainContributor?.agent.name.nn;
  const documentTypes = getDocumentTypes(work).map(({ format }) => format);

  return {
    id,
    publicationId,
    coverUrl: imageUrl,
    title: formattedTitle,
    creators: author ? [author] : [],
    mediaTypes: documentTypes ?? ["Ukjent"],
    workUrl: `/samling/verk/${work.id}`,
    workId: work.id,
  };
}
